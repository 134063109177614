import React, { useRef } from 'react';
import { string, bool, shape, number } from 'prop-types';

import classnames from 'classnames';

import useSpotlightSearch from './use-spotlight-search';
import Tooltip from '../tooltip/tooltip.mobile';
import { useStaticProps } from '../context/static-props';
import AnimatedSwitch from '../animated-switch/animated-switch';
import { useSearch } from '../../hooks/context';

const namespace = 'ui-search-spotlight';
const SPOTLIGHT_TOOLTIP_ID = 'shipping_quarantine';

const HIGHLIGHT_TITLE_MLA = 'Te lo llevamos a tu casa';
const HIGHLIGHT_TITLE_MLB = 'Enviamos para sua casa';
const HIGHLIGHT_TEXT_MLA =
  'Usá el filtro “Envío con normalidad” para encontrar los productos que te podemos seguir enviando mientras te cuidás.';
const HIGHLIGHT_TEXT_MLB =
  'Use o filtro “Enviando normalmente” para encontrar os produtos que vamos continuar enviando enquanto você fica em casa em segurança.';

const LONG_TEXT_LENGTH = 13;

const SpotlightSearch = ({ className, label, id, checked, href, toolbarVariant, tooltip }) => {
  const { shops } = useSearch();
  const isShops = Boolean(shops);
  const { siteId } = useStaticProps();
  const animatedSwitchElem = useRef(null);
  const title = siteId === 'MLA' ? HIGHLIGHT_TITLE_MLA : HIGHLIGHT_TITLE_MLB;
  const text = siteId === 'MLA' ? HIGHLIGHT_TEXT_MLA : HIGHLIGHT_TEXT_MLB;

  const handleClickComponent = (data, component) => {
    component.clickEvent();
    window.location.href = data;
  };

  const SpotlightContent = (
    <button
      type="button"
      tabIndex={0}
      onClick={() => handleClickComponent(href, animatedSwitchElem.current)}
      className={classnames(className, namespace, {
        [`${namespace}--toolbar-variant`]: toolbarVariant,
        shops__spotlight: isShops,
      })}
    >
      {label}
      <AnimatedSwitch
        ref={animatedSwitchElem}
        className={classnames(`${namespace}__link`, { [`${namespace}__link--toolbar-variant`]: toolbarVariant })}
        href={href}
        usePreventDefault="true"
        checked={checked}
        size="small"
      />
    </button>
  );

  if (id === SPOTLIGHT_TOOLTIP_ID) {
    return (
      <Tooltip id={SPOTLIGHT_TOOLTIP_ID} type="highlight" title={title} text={text}>
        {SpotlightContent}
      </Tooltip>
    );
  }

  if (tooltip) {
    return (
      <Tooltip
        id={tooltip.id}
        text={tooltip.content}
        offsetX={tooltip.offset_x}
        offsetY={tooltip.offset_y}
        type="highlight"
        maxPrint={tooltip.max_print}
      >
        {SpotlightContent}
      </Tooltip>
    );
  }

  return SpotlightContent;
};

SpotlightSearch.propTypes = {
  checked: bool.isRequired,
  className: string,
  href: string.isRequired,
  id: string.isRequired,
  label: string.isRequired,
};

SpotlightSearch.defaultProps = {
  className: null,
};

const SpotlightSearchContainer = ({ toolbarVariant }) => {
  const spotlight = useSpotlightSearch();

  if (!spotlight) {
    return null;
  }

  const props = {
    label: spotlight.render_text,
    href: spotlight.values[0].url,
    id: spotlight.id,
    checked: spotlight.extra_info.on_value,
    toolbarVariant,
    tooltip: spotlight.tooltip,
  };

  if (toolbarVariant) {
    return (
      <li
        className={classnames(`${namespace}__spotlight-wrapper`, {
          [`${namespace}__spotlight-wrapper--wide`]: spotlight.render_text.length > LONG_TEXT_LENGTH,
        })}
      >
        <SpotlightSearch {...props} />
      </li>
    );
  }

  return <SpotlightSearch {...props} />;
};

SpotlightSearchContainer.propTypes = {
  toolbarVariant: shape({}),
};
SpotlightSearch.propTypes = {
  toolbarVariant: shape({}),
  tooltip: shape({
    id: string,
    content: string,
    offset_x: number,
    offset_y: number,
    max_print: number,
  }),
};

SpotlightSearchContainer.defaultProps = {
  toolbarVariant: null,
};

export default SpotlightSearchContainer;

export { SpotlightSearch };
