import React from 'react';
import { string, node, shape, number } from 'prop-types';

import AndesTooltip from '@andes/tooltip';

import { useStaticProps } from '../context/static-props';
import useTooltip from '../../hooks/use-tooltip';
import Link from '../../components-v2/link';

const namespace = 'ui-search-tooltip';

const Tooltip = ({ className, title, text, action, children, id, type, side, offsetX, offsetY }) => {
  const { maxViewsTooltip } = useStaticProps();
  const [tooltipStatus, handleClose] = useTooltip(id);

  if (tooltipStatus?.wasClosed || tooltipStatus?.views > maxViewsTooltip) {
    return children;
  }

  const actionLink = action ? (
    <Link
      className={`${namespace}__action-link`}
      href={action.url}
      tabIndex="-1"
      isInternal={false}
      title={action.label}
    >
      {action.label}
    </Link>
  ) : null;

  return (
    <AndesTooltip
      actions={actionLink}
      className={`${namespace} ${className}`}
      visible
      title={title}
      side={side}
      offsetX={offsetX}
      offsetY={offsetY}
      content={text}
      mask
      maskCloseButton
      trigger="no-trigger"
      closable
      onClose={handleClose}
      type={type}
    >
      {children}
    </AndesTooltip>
  );
};

Tooltip.propTypes = {
  action: shape({
    label: string.isRequired,
    url: string.isRequired,
  }),
  children: node.isRequired,
  className: string,
  id: string.isRequired,
  offsetX: number,
  offsetY: number,
  side: string,
  text: string.isRequired,
  title: string,
  type: string,
};

Tooltip.defaultProps = {
  action: null,
  className: null,
  offsetX: 0,
  offsetY: 0,
  side: 'bottom',
  title: null,
  type: 'dark',
};

export default Tooltip;
